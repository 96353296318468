import 'react-app-polyfill/stable';
import 'core-js';
import React from 'react';
//import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import {
  //BrowserRouter,
  HashRouter,
} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-datasheet/lib/react-datasheet.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.min.js';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import 'src/SpectraView/Floors/sitemap.css';
import 'react-leaflet-fullscreen/styles.css';
import 'react-datepicker/dist/react-datepicker.css';

import './Common/mySpinner.css';
import './Common/style.css';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    {/* <BrowserRouter> */}
    <HashRouter>
      <App />
    </HashRouter>

    {/* </BrowserRouter> */}
  </Provider>,
);

// ReactDOM.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   document.getElementById('root'),
// )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
