import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function PrivateRoute({ component: Component, element, cb, ...rest }) {
  const auth = cb();
  console.log('PrivateRoute', auth);
  return auth ? <Outlet /> : <Navigate to="/login" />;
}

PrivateRoute.propTypes = {
  component: PropTypes.object,
  element: PropTypes.object,
  location: PropTypes.object,
  cb: PropTypes.func,
};
